<script>
export default {
    props: {
        dataSource: {
            type: Array
        },
        chartOptions: {
            type: Object
        }
    },
    computed: {
        height() {
            let window = globalThis;
            return Math.trunc(this.heightPortion * window.innerHeight / 100);
        },
        width() {
            let window = globalThis;
            return Math.trunc(this.widthPortion * window.innerWidth / 100);
        },
        series() {
            return this.$props.dataSource.map(i => {
                return {
                    name: i.text,
                    data: i.timeseries
                }
            });
        }
    },
    data() {
        return {
            heightPortion: 33,
            widthPortion: 34.7,
        };
    },
    methods: {
    }
};
</script>

<template>
    <div id="chartContainer">
        <apexchart type="line" :height="height" :width="width" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<style>
svg>text {
    fill: white;
}

.apexcharts-tooltip.apexcharts-theme-light {
    background: rgb(31 45 61)
}

.apexcharts-text tspan {
    fill: white;
}

.apexcharts-menu {
    background: rgb(31 45 61);
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: rgb(51 65 81)
}

.apexcharts-legend-text {
    color: white !important;
}
</style>