<script>
import XlsxExportEngine from "../../helpers/xlsxExportEngine"

export default {
  props: {
    dataSource: {
      type: Array
    },
  },
  data() {
    return {
    };
  },
  methods: {
    setupDefalutSorting() {
      this.$refs.innerGrid.currentSortKey = "report_date";
      this.$refs.innerGrid.currentSortType = "desc";
    },
    onExportButtonClicked() {
      new XlsxExportEngine().saveToXlsx(
        "details_table.xlsx",
        this.dataSource
      );
    }
  },
  mounted() {
    this.setupDefalutSorting();
  }
};
</script>

<template>
  <div>
    <div class="extra-panel">
      <h5 id="detailsTitle">Детали </h5>
      <vs-button type="relief" @click="onExportButtonClicked" size="small">Excel</vs-button>
    </div>
    <vs-table ref="innerGrid" :search="true" :data="dataSource" maxHeight="66vh">
      <template slot="thead">
        <vs-th sort-key="report_date">
          Дата
        </vs-th>
        <vs-th sort-key="z_score">
          Z крит.
        </vs-th>
        <vs-th>
          Объект
        </vs-th>
        <vs-th id="keyWordsCol">
          Ключевые слова
        </vs-th>
        <vs-th id="sourceMessageCol">
          Примеры упоминаний
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].report_date" class="small-column">
            {{ data[indextr].report_date }}
          </vs-td>

          <vs-td :data="data[indextr].z_score" class="small-column">
            {{ (data[indextr].z_score) ? data[indextr].z_score.toFixed(1) : "not calculated" }}
          </vs-td>

          <vs-td :data="data[indextr].ner_text" class="small-column">
            {{ data[indextr].ner_text }}
          </vs-td>

          <vs-td :data="data[indextr].keywords" class="midle-column">
            {{ (data[indextr].keywords != null) ? data[indextr].keywords.slice(0, 10).join(", ") : "None" }}
          </vs-td>

          <vs-td :data="data[indextr].messages">
            <p>
              {{ (data[indextr].messages != null) ? data[indextr].messages[0] : "None" }}
              <p />
            <p>
              {{ (data[indextr].messages != null) ? data[indextr].messages[1] : "None" }}
              <p />
            <p>
              {{ (data[indextr].messages != null) ? data[indextr].messages[2] : "None" }}
              <p />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<style>
.vs-table--header {
  background: rgb(40 61 85) !important;
}

.vs-con-table table {
  background: rgb(31 45 61);
}

tr {
  background: rgb(40 61 85) !important;
}

td {
  background: rgb(50 71 95);
}

.vs-table--td {
  max-width: 2vw;
  word-wrap: break-word;
}

.small-column {
  min-width: 5vw;
  width: 5vw;
}

.midle-column {
  min-width: 7vw;
  width: 7vw;
}

.extra-panel>* {
  display: inline
}
</style>