<script>
export default {
  emits: {
    selectedRowsChanged: null
  },
  props: {
    dataSource: {
      type: Array
    },
  },
  data() {
    return {
      selectedRows: [],
    };
  },
  methods: {
    onRowSelected(tr) {
      this.$emit("selected-rows-changed", {
        modifiedRow: tr,
        selectedRows: this.$data.selectedRows
      });
    },
    resetSelection() {
      this.selectedRows = [];
    },
  }
};
</script>

<template>
  <div>
    <h5 id="mainGridTitle">Топ популярных объектов</h5>
    <vs-table multiple :data="dataSource" v-model="selectedRows" maxHeight="38vh" @selected="onRowSelected">
      <template slot="thead">
        <vs-th id="typeCol">
          Тип
        </vs-th>
        <vs-th>
          Объект
        </vs-th>
        <vs-th id="quantCol" sort-key="current_quantity">
          Количество
        </vs-th>
        <vs-th id="percCol" sort-key="perc_change">
          Изменения
        </vs-th>
        <vs-th id="zCol" sort-key="z_score">
          Z крит.
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].ner_type">
            {{ data[indextr].ner_type }}
          </vs-td>
          <vs-td :data="data[indextr].ner_text">
            {{ data[indextr].ner_text }}
          </vs-td>
          <vs-td :data="data[indextr].current_quantity" class="small-column">
            {{ data[indextr].current_quantity }}
          </vs-td>
          <vs-td :data="data[indextr].perc_change" class="small-column">
            <span v-if="data[indextr].perc_change > 0" class="text-success">{{ data[indextr].perc_change + "%" }}</span>
            <span v-else-if="data[indextr].perc_change < 0" class="text-danger">{{ data[indextr].perc_change +
              "%" }}</span>
            <span v-else class="text-secondary">{{ data[indextr].perc_change + "%" }}</span>
          </vs-td>
          <vs-td :data="data[indextr].z_score" class="small-column">
            <span>{{ (data[indextr].z_score) ? data[indextr].z_score.toFixed(1) : "not calculated" }}</span>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<style>
.vs-table--header {
  background: rgb(40 61 85) !important;
}

.vs-con-table table {
  background: rgb(31 45 61);
}

tr {
  background: rgb(40 61 85) !important;
}

td {
  background: rgb(50 71 95);
}

.vs-table--td {
  word-wrap: break-word;
}

.con-td-check {
  background: rgb(40 61 85) !important;
}

.td-check {
  width: 0.9vw;
}

.small-column {
  min-width: 0.9vw;
  width: 0.9vw;
}
</style>